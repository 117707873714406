import React from "react";
import "./App.css";
import { Route, Routes } from "react-router";
import { BrowserRouter, Link } from "react-router-dom";
import { Login } from "./component/user/Login";
import { ForgotPassword } from "./component/user/ForgotPassword";
import { List } from "./component/transaction/List";
import { DMInit } from "digimaker-ui";
import { ResetPassword } from "./component/user/ResetPassword";
import { Button, Layout, Space } from "antd";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <br />
                <Link to="/login">
                  <Button>登陆</Button>
                </Link>
              </div>
            }
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="*"
            element={
              <DMInit
                viewSettings={(contenttype: string) => {
                  return {
                    inline_fields: ["name"],
                    browselist: {
                      viewmode: "list",
                      columns: [{ header: "id", field: "id" }],
                      sort_default: [["priority", "desc"]],
                    },
                  };
                }}
              >
                <Layout>
                  <Space>
                    <Link to="/transaction/list">我的交易</Link>
                    <Link to="/user/profile">我的信息</Link>
                  </Space>
                  <Layout>
                    <Layout.Content>
                      <Routes>
                        <Route
                          path="/transaction/list"
                          element={<List />}
                        ></Route>
                      </Routes>
                    </Layout.Content>
                  </Layout>
                </Layout>
              </DMInit>
            }
          ></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route
            path="/reset-password/:hash"
            element={<ResetPassword />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
