import { Input, Row, Col, Space, Button, Form, Alert } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  const [status, setStatus] = useState(0); //0 - initial, 2 - sending, 3 - error, 1 - success
  const [message, setMessage] = useState("");
  const submit = (values) => {
    const email = values["email"];

    setStatus(2);
    setMessage("正在处理...");
    fetch(process.env.REACT_APP_REMOTE_URL + "/user/resetpassword/" + email, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error === false) {
          setStatus(1);
          setMessage("邮件已经发送，请查看邮箱!");
        } else {
          setStatus(3);
          setMessage(data.data.message);
        }
      });
  };

  return (
    <div>
      <h2>忘记密码</h2>

      <Form onFinish={submit} style={{ maxWidth: 600 }} disabled={status === 2}>
        <Form.Item
          name="email"
          label="请输入邮箱: "
          rules={[
            {
              type: "email",
              message: "不是有效的邮箱!",
            },
            {
              required: true,
              message: "请输入邮箱!",
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Row>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                发送邮件
              </Button>
              <Link to="/">返回</Link>
            </Space>
          </Col>
        </Row>

        {message && (
          <div>
            <br />
            <Alert message={message} type={status === 3 ? "error" : "info"} />
          </div>
        )}
      </Form>
    </div>
  );
};
