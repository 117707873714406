import { Button, Col, Form, Input, message, Row, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import util from "digimaker-ui/util";

export const Login = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const submit = async (values) => {
    fetch(process.env.REACT_APP_REMOTE_URL + "/auth/auth", {
      method: "post",
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error === false) {
          util.setRefreshToken(res.data.refresh_token);
          navigate("/");
        } else {
          messageApi.error(res.data.message);
        }
      })
      .catch((err) => {
        messageApi.error(err.toString());
      });
  };

  return (
    <div>
      <h2>导游登陆</h2>
      {contextHolder}
      <Form onFinish={submit} style={{ maxWidth: 600, margin: "auto" }}>
        <Form.Item name="username">
          <Input type="text" />
        </Form.Item>
        <Form.Item name="password">
          <Input type="password" />
        </Form.Item>
        <Row>
          <Col>
            <Space>
              <Button type="primary" htmlType="submit">
                登陆
              </Button>
              <Link to="/forgot-password">忘记密码</Link>
            </Space>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
