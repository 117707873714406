import { Input, Row, Col, Space, Button, Form, Alert } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

export const ResetPassword = () => {
  const { hash } = useParams();

  const [status, setStatus] = useState(0); //0 - initial, 2 - sending, 3 - error, 1 - success
  const [message, setMessage] = useState("");

  const submit = (values) => {
    if (hash) {
      setStatus(2);
      setMessage("正在发送...");
      const password = values["password"];
      const password2 = values["password2"];
      if (password !== password2) {
        setStatus(3);
        setMessage("密码不一致!");
        return;
      }
      fetch(
        process.env.REACT_APP_REMOTE_URL +
          "/user/resetpassword-confirm/" +
          hash,
        {
          method: "POST",
          body: JSON.stringify({
            password: password,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false) {
            setStatus(1);
            setMessage("成功！");
          } else {
            setStatus(3);
            setMessage(data.data.message);
          }
        });
    }
  };

  return (
    <div>
      <h2>忘记密码</h2>
      {hash && (
        <Form
          onFinish={submit}
          style={{ maxWidth: 600 }}
          disabled={status === 2}
        >
          <Form.Item name="password" label="请输入密码: " required>
            <Input type="password" minLength={6} />
          </Form.Item>
          <Form.Item name="password2" label="请再输入密码: " required>
            <Input type="password" minLength={6} />
          </Form.Item>
          <Row>
            <Col>
              <Space>
                <Button type="primary" htmlType="submit">
                  修改密码
                </Button>
                <Link to="/">返回</Link>
              </Space>
            </Col>
          </Row>
          {message && (
            <div>
              <br />
              <Alert message={message} type={status === 3 ? "error" : "info"} />
            </div>
          )}
        </Form>
      )}

      {!hash && <Alert message="错误参数" type="error" />}
    </div>
  );
};
